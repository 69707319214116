import request from "@pacvue/frame/request"

const { VITE_APP_WALMART_BASE_URL, VITE_APP_MENU_API, VITE_APP_ADMIN_MIDDLE } = import.meta.env

export function getUserGuidanceStep() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/Guidance`,
    method: "GET"
  })
}

export function postUserGuidanceStep(params) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/Guidance`,
    method: "PUT",
    params: params
  })
}

export function getEmailNotificationsStatus() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Notification`,
    method: "GET"
  })
}

export function setEmailNotificationsStatus(params) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Notification/Status`,
    method: "PATCH",
    params: params
  })
}

export function setOOBNotificationStatus(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Notification/OutOfDailyBudget`,
    method: "POST",
    data: data
  })
}

export function setILHNotificationStatus(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Notification/ItemLowHealth`,
    method: "POST",
    data: data
  })
}

export function setASANotificationStatus(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Notification/ActualSpend`,
    method: "POST",
    data: data
  })
}

export function saveEventLog(data) {
  return request({
    url: `${VITE_APP_MENU_API}user/saveEventLog`,
    method: "POST",
    data: data
  })
}

export function postEditUserPosition(data) {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}user/companyRole/edit`,
    method: "POST",
    data
  })
}
