import request from "@pacvue/frame/request"
const { VITE_APP_WALMART_BASE_URL } = import.meta.env

export function postTagWithItems(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "ItemTags/TagWithItems",
    method: "post",
    data
  })
}

export function putItemTags(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "ItemTags",
    method: "put",
    data
  })
}
export function getItemTags(mode = "Item") {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    method: "get"
  })
}
export function GetItemSubTagData(parentId, data) {
  var url = `Report/ItemSubTag/list?parentId=${parentId}`
  return request({
    url: VITE_APP_WALMART_BASE_URL + url,
    data,
    method: "post"
  })
}

export function getItemByItemTagId(id) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "ItemTags/" + id,
    method: "get"
  })
}
export function putItemByItemTagId(id, data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "ItemTags/" + id,
    method: "put",
    data
  })
}

export function deleteItemTags(id) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "ItemTags?id=" + id,
    fullData: true,
    method: "delete"
  })
}
